@import "src/styles/animations";
@import "src/styles/breakpoints";
@import "src/styles/colors";
@import "src/styles/shadows";

.container {
  position: relative;
  width: 100%;
  padding: 24px;
  border-radius: 18px;
  background-color: $white-color;
  box-shadow: $resting-shadow;
  text-align: left;
  cursor: pointer;

  .open {
    position: absolute;
    top: 26px;
    right: 24px;
    width: 14px;
    height: 14px;
    color: $light-blue-color;
    transform: rotate(45deg);
    transform-origin: center;
    transition: transform $default-transition;

    &.close {
      transform: rotate(0);
    }
  }

  .question {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: $black-color;
    transition: color $default-transition;

    &.questionOpen {
      color: $light-blue-color;
    }
  }

  .answer {
    max-height: 0;
    margin: 0;
    font-weight: 300;
    color: $dark-grey-color;
    opacity: 0;

    transition: max-height $default-transition, margin $default-transition,
      opacity $default-transition;

    &.answer-open {
      max-height: 256px;
      margin-top: 8px;
      opacity: 1;

      @include up("md") {
        margin-top: 16px;
      }
    }
  }
}
