@import "src/styles/animations";
@import "src/styles/breakpoints";
@import "src/styles/colors";
@import "src/styles/shadows";

.header {
  position: sticky;
  top: 0;
  z-index: 2;

  transition: background-color $default-transition,
    backdrop-filter $default-transition, box-shadow $default-transition;

  @include up("md") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.header-with-shadow {
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
    box-shadow: $elevated-shadow;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    max-height: 80px;
    padding: 16px 0;
    margin: 0 24px;

    @include up("md") {
      max-height: 112px;
      width: 960px;
      padding: 32px 0;
      margin: 0 32px;
    }

    .header-logo {
      width: auto;
      height: 48px;
    }
  }
}

// buttons

.cta-button {
  margin-top: 32px;

  @include up("md") {
    margin-top: 48px;
  }
}

// illustrations

.block-factory {
  margin-top: 48px;
}

.world-map {
  margin-top: 32px;

  @include up("md") {
    margin-top: 0;
  }
}

.illustration {
  max-width: 100%;
  height: auto;
}

// sections

.hero-wrapper {
  padding-bottom: 60px;
  background: linear-gradient(to right, $dark-blue-color, $light-blue-color);

  .hero {
    display: grid;
    grid-template-columns: 1fr;
    padding: 32px 0;

    @include up("md") {
      grid-template-columns: 1.618fr 1fr;
      padding: 48px 0;
    }

    .hero-content {
      @include up("md") {
        margin-right: 48px;
      }

      .hero-description {
        margin: 0;
        margin-top: 24px;
        font-weight: 300;
        color: $white-color;

        @include up("md") {
          margin-top: 32px;
        }
      }

      .hero-stats {
        margin-top: 32px;

        @include up("md") {
          margin-top: 48px;
        }
      }
    }
  }
}

.offering {
  padding: 48px 0;
  text-align: center;

  .offering-cards {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 24px;
    margin-top: 24px;

    @include up("sm") {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }

    @include up("md") {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 32px;
      margin-top: 32px;
    }
  }
}

.mission-wrapper {
  background: linear-gradient(to top, $dark-blue-color, $light-blue-color);

  .mission {
    padding: 48px 0;
    text-align: center;

    .mission-content {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: 1fr;

      @include up("md") {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1.618fr;
        align-items: center;
      }

      .mission-text {
        font-weight: 300;
        text-align: center;
        color: $white-color;

        @include up("md") {
          margin-right: 48px;
          text-align: left;
        }

        .mission-description {
          margin: 0;
          margin-top: 24px;
          text-align: center;

          @include up("md") {
            text-align: inherit;
          }
        }
      }
    }
  }
}

.faq-wrapper {
  padding-bottom: 60px;

  .faq {
    padding: 48px 0;
    text-align: center;

    .faq-item {
      margin-top: 24px;

      @include up("md") {
        margin-top: 32px;
      }
    }
  }
}

.footer {
  position: relative;
  background: linear-gradient(to top, $dark-blue-color, $light-blue-color);

  @include up("md") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    margin: 0 24px;

    @include up("md") {
      width: 960px;
      padding: 32px 0;
      margin: 0 32px;
    }

    .footer-logo {
      align-self: center;
      width: auto;
      height: 72px;
      object-fit: contain;
    }

    .footer-social {
      display: flex;
      justify-content: center;
      margin-top: 32px;

      @include up("md") {
        margin-top: 48px;
      }

      .footer-social-icon {
        width: 32px;
        height: 32px;
        margin: 0 16px;
      }
    }

    .footer-address {
      margin: 0;
      margin-top: 16px;
      font-weight: 300;
      text-align: center;
      color: $white-color;

      @include up("md") {
        margin-top: 24px;
      }
    }

    .footer-divider {
      margin: 16px 0;
      border: none;
      border-top: 1px solid $light-blue-color;
      border-radius: 0.5px;

      @include up("md") {
        margin: 24px 0;
      }
    }

    .footer-copyright {
      margin: 0;
      font-weight: 300;
      text-align: center;
      color: $white-color;

      // @media (min-width: ${breakpoints.md}) {
      //   text-align: left;
      // }
    }
  }
}

.modal {
  // text-align: center;

  @include up("md") {
    text-align: center;
  }
}
