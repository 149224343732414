@import "src/styles/breakpoints";
@import "src/styles/colors";

.container {
  display: flex;
  justify-content: center;
  margin-top: 16px;

  @include up("md") {
    margin-top: 24px;
  }

  .flag-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid $white-color;
    margin: 0 16px;
    object-fit: cover;
  }
}
