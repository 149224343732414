@import "src/styles/animations";
@import "src/styles/colors";
@import "src/styles/mixins";
@import "src/styles/shadows";

.container {
  @include button-reset;

  &:focus {
    outline: 0;
  }

  min-width: 128px;
  padding: 14px 24px;
  border-radius: 14px;
  background-color: $white-color;
  box-shadow: $resting-shadow;
  font: inherit;
  font-weight: 500;
  line-height: 20px;
  color: $dark-blue-color;
  transition: box-shadow $default-transition, transform $default-transition;

  &:hover {
    cursor: pointer;
    box-shadow: $elevated-shadow;
    transform: translateY(-2px);
  }

  &.glass {
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
  }
}
