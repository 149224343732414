@mixin button-reset {
  padding: 0;
  border: none;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

@mixin input-reset {
  display: block;
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  width: auto;
  vertical-align: middle; // IE6,IE7
  white-space: normal;
  line-height: inherit;
  background: none;
  -webkit-appearance: none; /* stylelint-disable-line */
  -moz-appearance: none; /* stylelint-disable-line */
  -ms-appearance: none; /* stylelint-disable-line */
  appearance: none;

  /* Browsers have different default form fonts */
  color: inherit;
  font-size: inherit;
  font-family: inherit;

  &:focus {
    outline: none;
  }
}

@mixin full-viewport-height {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

@mixin full-viewport-min-height {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
}

@mixin full-viewport-max-height {
  max-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  max-height: calc(var(--vh, 1vh) * 100);
}
