@import "src/styles/breakpoints";
@import "src/styles/colors";

.wrapper {
  position: relative;
  background-color: $white-color;

  @include up("md") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.wrapper-with-wave {
    padding-bottom: 60px;
  }
}

.container {
  margin: 0 24px;

  @include up("md") {
    width: 960px;
    margin: 0 32px;
  }
}
