@import "src/styles/breakpoints";
@import "src/styles/colors";

.base {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  color: $black-color;

  @include up("md") {
    font-size: 3em;
  }
}

.thin {
  font-weight: 300;
  color: $light-blue-color;
}

.monotone {
  color: $white-color;
}
