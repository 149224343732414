@import "src/styles/animations";
@import "src/styles/breakpoints";
@import "src/styles/colors";
@import "src/styles/mixins";
@import "src/styles/shadows";

.wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(0);
  transform: scale(0);
  transition: background-color $default-transition,
    backdrop-filter $default-transition,
    transform 0ms $ease-in-out-easing $standard-duration;

  &.wrapper-open {
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
    transform: scale(1);
    transition: background-color $default-transition,
      backdrop-filter $default-transition;
  }
}

.container {
  position: relative;
  width: calc(100% - 64px);
  margin: 32px;
  border-radius: 18px;
  background-color: $white-color;
  box-shadow: $elevated-shadow;
  transform: scale(0);
  transform-origin: top right;
  transition: transform $default-transition, opacity $default-transition;
  opacity: 0;

  @include up("sm") {
    max-width: 960px;
  }

  &.container-open {
    transform: scale(1);
    opacity: 1;
  }
}

.close {
  @include button-reset;

  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;

  .close-icon {
    width: 16px;
    height: 16px;
  }
}

.content {
  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 24px;
}
