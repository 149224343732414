@import "src/styles/breakpoints";
@import "src/styles/colors";
@import "src/styles/shadows";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 24px 24px;
  border-radius: 18px;
  background-color: $white-color;
  box-shadow: $resting-shadow;

  .image-wrapper {
    margin: 16px 32px;

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding: calc((100% - (100% / 1.618)) / 2);
      border-radius: 50%;
      background: linear-gradient(
        to bottom,
        $dark-blue-color,
        $light-blue-color
      );
    }
  }

  .title {
    margin: 0;
    margin-top: 8px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: $black-color;

    @include up("md") {
      margin-top: 16px;
    }
  }

  .description {
    margin: 0;
    margin-top: 8px;
    font-weight: 300;
    color: $dark-grey-color;

    @include up("md") {
      margin-top: 16px;
    }
  }
}
