$breakpoints: (
  "xs": 0px,
  "sm": 600px,
  "md": 960px,
  "lg": 1280px,
);

@mixin up($width) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @media only screen and (min-width: $width) {
      @content;
    }
  }
}
