@import "src/styles/colors";

.container {
  .text {
    margin: 0;
    margin-top: 6px;
    font-weight: 300;
    color: $white-color;

    .bold {
      font-weight: 500;
    }
  }
}
