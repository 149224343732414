@keyframes wave-to-left {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes wave-to-right {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

.container {
  position: absolute;
  bottom: 0;
  left: 0px;
  display: block;
  width: 100%;
  max-height: 48px;
  margin: 0;
  z-index: 1;

  .wave-one {
    animation: wave-to-left 10s linear infinite;

    &:nth-child(1) {
      animation-delay: -2s;
    }
  }

  .wave-two {
    animation: wave-to-right 8s linear infinite;

    &:nth-child(1) {
      animation-delay: -2s;
    }
  }

  .wave-three {
    animation: wave-to-left 6s linear infinite;

    &:nth-child(1) {
      animation-delay: -2s;
    }
  }

  .wave-four {
    animation: wave-to-right 4s linear infinite;

    &:nth-child(1) {
      animation-delay: -2s;
    }
  }
}
